/* Fonts */
@font-face {
    font-family: "ubuntu-light";
    src: url("../fonts/Ubuntu/Ubuntu-Light.eot");
    /* IE9 Compat Modes */
    src: url("../fonts/Ubuntu/Ubuntu-Light.woff2") format("woff2"), url("../fonts/Ubuntu/Ubuntu-Light.woff") format("woff"), url("../fonts/Ubuntu/Ubuntu-Light.ttf") format("truetype");
}

@font-face {
    font-family: "ubuntu-regular";
    src: url("../fonts/Ubuntu/Ubuntu-Regular.eot");
    /* IE9 Compat Modes */
    src: url("../fonts/Ubuntu/Ubuntu-Regular.woff2") format("woff2"), url("../fonts/Ubuntu/Ubuntu-Regular.woff") format("woff"), url("../fonts/Ubuntu/Ubuntu-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "ubuntu-medium";
    src: url("../fonts/Ubuntu/Ubuntu-Medium.eot");
    /* IE9 Compat Modes */
    src: url("../fonts/Ubuntu/Ubuntu-Medium.woff2") format("woff2"), url("../fonts/Ubuntu/Ubuntu-Medium.woff") format("woff"), url("../fonts/Ubuntu/Ubuntu-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "ubuntu-bold";
    src: url("../fonts/Ubuntu/Ubuntu-Bold.eot");
    /* IE9 Compat Modes */
    src: url("../fonts/Ubuntu/Ubuntu-Bold.woff2") format("woff2"), url("../fonts/Ubuntu/Ubuntu-Bold.woff") format("woff"), url("../fonts/Ubuntu/Ubuntu-Bold.ttf") format("truetype");
}

// $helvetica: 'Helvetica Neue';
$helvetica: "ubuntu-regular";

$c-black: #18181a !default;

$lightGrey: #F3F3F3;

$dark_bg: #171f29 !important;

$white_shade: #ffffff;

$dark_border: #252d37;

/************************ DASHBOARD UI KIT ***********************/

$ui_header_bg: #131523;

$grey: #e8eef3;

$light_grey: #f1f1f3;

$dark_grey: #616e80;

$darkest_grey: #4d4f5c;

$dark_text: #28313c;

$light_text: #99A5B5;

$blue: #1d82f5;

$red: #D30000;

$yellow: #FCBD01;

$light_green: #39e500;

$dark_green: #2CB100;

$additional_grey: #F2F4F7;

$pink: #ea4c89;

$amt_grey: #e7e9eb;

$prurple: #B66DFF;
